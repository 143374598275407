






import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class NavigationLink extends Vue {
  @Prop()
  readonly to!: string;

  @Prop({ default: false })
  readonly external!: boolean;

  @Prop({ default: false })
  readonly telOrMailTo!: boolean;

  handleClicked(e: Event) {
    e.preventDefault();

    this.$emit('click');

    if (this.external) {
      window.open(this.to, '_blank');
    } else if (this.telOrMailTo) {
      window.location.href = this.to;
    } else if (this.$route.path !== this.to) {
      this.$router.push(this.to);
    }
  }
}
