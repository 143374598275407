





// import { Office } from 'client-website-ts-library/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Config } from 'client-website-ts-library/services';

import NavigationLink from './NavigationLink.vue';

@Component({
  components: {
    NavigationLink,
  },
})
export default class Header extends Vue {
  @Prop()
  private readonly mobileNavActive!: boolean;

  @Prop()
  private readonly isStuck!: boolean;

  //   @Prop()
  //   private readonly offices!: Office[];
}
