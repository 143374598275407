import { API } from 'client-website-ts-library/services';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
// import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/*',
    name: 'Not Found',
    meta: {
      hide: true,
      hideOfficeMap: true,
    },
    component: () => import('../views/Home.vue'),
    beforeEnter: (to, from, next) => {
      if (to.fullPath) {
        API.Slugs.Resolve(to.fullPath.split(' ').join('-')).then((slug) => {
          switch (slug.Type) {
            case 'Listing':
              next({
                path: `/listings/${slug.Slug}`,
                replace: true,
              });
              break;
            case 'Profile':
              next({
                path: `/people/${slug.Slug}`,
                replace: true,
              });
              break;
            case 'Office':
              API.Offices.Get(slug.Id).then((office) => {
                next({
                  path: `/offices/${office.OfficeSlug}`,
                  replace: true,
                });
              }).catch(() => {
                next();
              });
              break;
            default:
              next();
              break;
          }
        }).catch(() => {
          next();
        });
      } else {
        next();
      }
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (from && from.path === to.path) return null;

    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
});

export default router;
