































// Base CSS
import './assets/css/reset.css';
import './assets/css/fonts.css';
import './assets/css/layout.css';
import './config.css';

import { Component, Vue, Prop } from 'vue-property-decorator';

import { Config } from 'client-website-ts-library/services';
import { ClientWebsiteStatus } from 'client-website-ts-library/types/WebsiteConfig';

import Header from './components/Common/Header.vue';
import Footer from './components/Common/Footer.vue';
import NotificationStack from './components/Notifications/NotificationStack.vue';

@Component({
  components: {
    Header,
    Footer,
    NotificationStack,
  },
})
export default class App extends Vue {
  @Prop()
  private readonly errored!: boolean;

  get websiteName(): string {
    return Config.Website.Name;
  }

  get isDisabled(): boolean {
    return Config.Website.Settings!.Status === ClientWebsiteStatus.Disabled;
  }

  created(): void {
    const hostname = window.location.hostname.replace('www.', '');
    const pathname = window.location.pathname;

    const path = hostname + pathname;

    if (path !== 'explorepropertytownsville.com.au/' && path !== 'localhost/'
      && path !== 'explorepropertytownsville.com.au/listings/for-sale'
      && path !== 'explorepropertytownsville.com.au/listings/for-rent'
      && path !== 'explorepropertytownsville.com.au/people'
      && path !== 'explorepropertytownsville.com.au/contact'
      && path !== 'explorepropertytownsville.com.au/listings/sold'
      && path !== 'explorepropertytownsville.com.au/renting-a-property'
    ) {
      window.location.href = `https://explorepropertycentral.com.au${pathname}`;
    }
  }
}
